import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TempRedirect from "./components/temp-redirect";
import MainRedirect from "./components/main-redirect";
import FeedPost from "./components/feed-post";

function App() {
  const reload = () => window.location.reload();
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<MainRedirect />} />
          <Route path="/apple-app-site-association" onEnter={reload} />
          <Route path="/:link" exact element={<TempRedirect />} />
          <Route path="/p/:link" exact element={<FeedPost />} />
          <Route path="/i/:link" exact element={<TempRedirect />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
