import React from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import logo from "../../assets/images/logo.svg";
import { isMobile } from 'react-device-detect';

function Navbar({ buttonText }) {
    return (
    <div className="navbar">
        <div className="navbar-container">
        <Link to="/" className="navbar-name">
            <img src={logo} className="img-cover" alt="Vama"/>
        </Link>
        <a href="https://apps.apple.com/us/app/vama/id1600580466" className="vama-page-download-button">
            <div className="inner-download">{buttonText ? buttonText : 'Download'}</div>
        </a>
    </div>
    </div>    
    )
}

export default Navbar