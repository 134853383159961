import React, { useEffect } from "react";

function MainRedirect() {

  useEffect(() => {
    window.location.href = "https://vama.com"
  }, []);

  return (
    <div>
    </div>
  );
}

export default MainRedirect;